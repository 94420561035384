
<template>
  <div class="requirements">
    <div class="row">
      <div class="col-12">
        <h3 class="my-3"> Requirements </h3>
        <div class="row mb-2">
          <div class="col-sm-12 col-md-8 mb-2 mb-md-0">
            <form class="form-inline">
              <label class="d-inline-flex mb-0 mr-2 align-items-center">
                  <b-form-select v-model="itemsPerPage" :options="pageOptions"></b-form-select>
              </label>
              <button class="btn text-white btn-primary" type="button" @click.prevent="popupModalShow = true">
                  <i class="mdi mdi-plus-circle"></i> Add Requirement
              </button>
            </form>
          </div>
          <div class="col-sm-12 col-md-4">
              <div class="">
                  <label class="d-block">
                      <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control"></b-form-input>
                  </label>
              </div>
          </div>
        </div>
        <is-loading v-if="isLoading" />
        <div v-else class="row">
          <div class="col-md-12">
            <div class="mb-3">
              <p class="mb-0">Showing {{paginationInfo}} results</p>
            </div>
            <div role="tablist" class="">
              <b-card no-body class="mb-1 shadow-none" 
                v-for="(requirement, index) in pageData.data" :key="index">
                <b-card-header header-tag="header" class="bg-transparent" role="tab">
                  <div class="d-sm-flex">
                    <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                      <a v-b-toggle="`addonAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                        <i class="mdi mdi-check-circle mr-1 text-primary"></i>{{requirement.name}}
                      </a>
                    </h5>
                  </div>
                </b-card-header>
                <b-collapse :id="`addonAccordion-${index}`" accordion="accordion" role="tabpanel">
                  <b-card-body>
                    <requirement-card :item="requirement">
                      <template v-slot:actions>
                        <div>
                          <b-button variant="success" size="sm" class="mr-2" @click.prevent="initEdit(requirement)" type="button">
                            Edit </b-button>
                          <b-button variant="primary" v-if="requirement.multiple" size="sm" class="mr-2"
                           @click.prevent="showValues(requirement)" type="button">
                            View Values </b-button>
                          <b-button variant="danger" size="sm" @click.prevent="deleteItem(requirement.id)" type="button">
                            Delete</b-button>
                        </div>
                      </template>
                    </requirement-card>
                    <div v-if="isShowItemValues && showItem.id == requirement.id">
                      <hr />
                      <requirement-values :showItem="requirement" />
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="col">
              <div class="py-4 paging_simple_numbers">
                  <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                  :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
              </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode ? 'Update requirement':'Add requirement'"
      title-class="" @hide="initializeAll()">
      <requirement-form v-if="popupModalShow" @closeMe="initializeAll()"
       :editMode="editMode" :editItem="showItem" />
    </b-modal>
  </div>
</template>

<script>
import RequirementForm from '@/components/forms/RequirementForm.vue';
import RequirementCard from '@/components/cards/RequirementCard.vue';
import RequirementValues from './RequirementValues.vue';
import IsLoading from '@/components/IsLoading.vue';
import _ from 'lodash';

export default {
  components: {
    RequirementForm, RequirementCard, 
    RequirementValues,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      isShowItemValues: false,
      editMode: false,
      showItem: null,
      searchQuery:"",
      pageOptions: [10, 25, 50, 100],
    }
  },
  computed: {
    pageData(){
      return this.$store.state.requirement.pageData
    },
    reviews() {
      return this.pageData.data
    },
    currentPage: {
      get() {
          return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("requirement/fetchItems", payload)
      .then(response => this.isLoading = false)
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this requirement!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/requirements/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
               this.$store.commit("requirement/DELETE_ITEM", itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.showItem = {...item}
      this.popupModalShow = true  
    },
    showValues(item){
      if(this.isShowItemValues) {
        this.initializeAll(); return
      }
      this.showItem = {...item}
      this.isShowItemValues = true  
    },
    initializeAll(){
      this.editMode = false;
      this.showItem = null;
      this.popupModalShow = false;
      this.isShowItemValues = false;
    },
  },
  mounted(){
    this.fetchItems()
  }

}
</script>
